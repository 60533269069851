<template>
  <v-app>
    <div
      style="
        background-image: url(/media/anjungan/BG-1280x1024.jpg);
        height: 100%;
        background-size: cover;
      "
    >
      <!-- Header::Start -->
      <div class="curved-header sample1">
        <div class="row px-10">
          <div class="col-1 pt-10">
            <img
              style="
                max-height: 198px;
                min-height: 198px;
                position: relative;
                top: 60px;
                left: 11px;
              "
              :src="config.city_logo"
              alt="LOGO DESA"
            />
          </div>
          <div class="col text-center pt-10 px-19">
            <h1 style="font-size: 54px; color: #1dac74">
              ANJUNGAN DESA MANDIRI
            </h1>
            <span
              style="font-size: 25px; text-align: justify; color: #3b4c42"
              >{{ config.address }}</span
            >
          </div>
        </div>
      </div>
      <!-- Header::End -->

      <!-- Menu::Start -->
      <div class="container mt-4" style="overflow: scroll; max-height: 560px">
        <!-- Breadcrumb::Start -->
        <h1 style="font-size: 35px; color: #bbc15d; font-weight: 600">
          <span @click="$router.push('/anjungan-mandiri/layanan-mandiri')">
            Layanan Mandiri</span
          >
          <span style="color: white"> > </span>
          <span @click="$router.push('/anjungan-mandiri/surat-menyurat')">
            Surat Menyurat</span
          >
          <span style="color: white"> > </span>
          <span style="font-size: 44px; color: white"> Ijin Usaha</span>
        </h1>
        <!-- Breadcrumb::End -->
        <div class="mt-auto" style="position: relative; top: 17px">
          <span style="font-size: 30px; color: white; font-weight: bold">
            Form Ijin Usaha</span
          >
          <b-form @submit.stop.prevent="formOnsubmit">
            <label
              for="input-id_card_number"
              style="color: white; font-size: 22px; font-weight: 600"
              >NIK:</label
            >
            <b-form-group id="input-group-id_card_number">
              <b-form-input
                id="input-id_card_number"
                v-model="IdCardNumber"
                placeholder="NIK"
                size="lg"
                style="font-size: 25px"
              ></b-form-input>
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.id_card_number }}</small
              >
            </b-form-group>
            <label
              for="input-family_number"
              style="color: white; font-size: 22px; font-weight: 600"
              >No. KK:</label
            >
            <b-form-group id="input-group-family_number">
              <b-form-input
                id="input-family_number"
                v-model="FamilyNumber"
                placeholder="No. KK"
                size="lg"
                style="font-size: 25px"
              ></b-form-input>
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.family_number }}</small
              >
            </b-form-group>
            <label
              for="input-hamlet_id"
              style="color: white; font-size: 22px; font-weight: 600"
              >Pilih No. WA Ketua RW:</label
            >
            <b-form-group id="input-group-hamlet_id">
              <treeselect
                v-model="form.hamlet_id"
                :multiple="false"
                placeholder="Pilih No. WA Ketua RW"
                :options="hamlets"
                style="font-size: 25px"
              />
              <small
                class="text-danger"
                style="
                  font-size: 25px;
                  font-weight: bolder;
                  color: #bbc15d !important;
                "
                >{{ error.hamlet_id }}</small
              >
            </b-form-group>
            <label
              for="input-start-date"
              style="color: white; font-size: 22px; font-weight: 600"
              >Usaha Mulai Sejak:</label
            >
            <div class="row mb-2">
              <div class="col pt-3">
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="form.start_date"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-input-group>
                      <b-input-group-prepend>
                        <button
                          class="btn btn-secondary"
                          type="button"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="fas fa-calendar"></i>
                        </button>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-trigger-modal-patient"
                        v-model="form.start_date"
                        placeholder="Tanggal (YYYY-MM-DD)"
                        readonly
                        size="lg"
                        style="font-size: 25px"
                      >
                      </b-form-input>
                    </b-input-group>
                  </template>

                  <v-date-picker
                    v-if="modal2"
                    v-model="form.start_date"
                    locale="id"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(form.start_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
            <label
              for="input-name"
              style="color: white; font-size: 22px; font-weight: 600"
              >Nama Usaha:</label
            >
            <b-form-group id="input-group-name">
              <b-form-input
                id="input-name"
                v-model="form.name"
                placeholder="Nama Usaha"
                size="lg"
                style="font-size: 25px"
              ></b-form-input>
              <small class="text-danger">{{ error.name }}</small>
            </b-form-group>
            <label
              for="input-purpose"
              style="color: white; font-size: 22px; font-weight: 600"
              >Tujuan Pembuatan Surat:</label
            >
            <b-form-group id="input-group-purpose">
              <b-form-input
                id="input-purpose"
                v-model="form.purpose"
                placeholder="Tujuan Pembuatan Surat"
                size="lg"
                style="font-size: 25px"
              ></b-form-input>
              <small class="text-danger">{{ error.purpose }}</small>
            </b-form-group>
            <label
              for="input-address"
              style="color: white; font-size: 22px; font-weight: 600"
              >Alamat Usaha:</label
            >
            <b-form-group id="input-group-address">
              <b-form-textarea
                id="input-address"
                v-model="form.address"
                placeholder="Alamat Usaha"
                rows="4"
                max-rows="8"
                style="font-size: 25px"
              ></b-form-textarea>
              <small class="text-danger">{{ error.address }}</small>
            </b-form-group>
            <b-button
            type="submit"
            size="lg"
            variant="white"
            style="
              height: 58px;
              width: 125px;
              font-size: 23px;
              font-weight: 600;
            "
            >Simpan</b-button
          >
          <b-button
            type="button"
            class="ml-2"
            style="
              background: #bbc15d;
              border-color: #bbc15d;
              height: 58px;
              width: 125px;
              font-size: 23px;
              font-weight: 600;
            "
            size="lg"
            @click="$router.push('/anjungan-mandiri/layanan-mandiri')"
          >
            Batal
          </b-button>
          </b-form>
        </div>
      </div>
      <!-- Menu::End -->
      <!-- Footer::Start -->
      <div class="curved-footer sample2">
        <div class="d-flex justify-content-center">
          <h1 style="font-size: 100px; font-weight: 600; color: #1dac74">
            {{ time }}
          </h1>
        </div>
        <div class="d-flex justify-content-center">
          <span style="color: #3b4c42; font-size: 30px" class="text-uppercase"
            >selamat datang di website desa {{ config.village_name }}</span
          >
        </div>
      </div>
      <!-- Footer::End -->
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      config: {},
      interval: null,
      time: null,
      timeout: null,
      timeout2: null,
      form: {
        title: "Surat Ijin Usaha",
        letter_type_id: 3,
      },
      error: {
        id_card_number: "",
        family_number: "",
        hamlet_id: "",
        start_date: "",
        name: "",
        purpose: "",
        address: "",
      },
      modal2: false,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua Rw",
          id: "",
          isDisabled: true,
        });
      }
    },
    reset() {
      this.form.id_card_number = "";
      this.form.family_number = "";
      this.form.hamlet_id = "";
      //
      this.form.name = "";
      this.form.purpose = "";
      this.form.address = "";
      this.form.start_date = "";

      this.error.id_card_number = "";
      this.error.family_number = "";
      this.error.hamlet_id = "";
      //
      this.error.name = "";
      this.error.purpose = "";
      this.error.address = "";
      this.error.start_date = "";
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        "bussiness-certificate-no-auth"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.reset();
      }
    },
  async checkIdCardNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        "check-id-card-number"
      );
      // Check Response
      if (response.state == "error") {
        Swal.fire(
          "",
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.",
          "error"
        );
        this.error.id_card_number =
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.";
      } else {
        this.error.id_card_number = "";
      }
    },
    async checkFamilyNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        `check-compatibility/${this.form.id_card_number}/${this.form.family_number}`
      );
      console.log("resp", response);
      // Check Response
      if (response.success == "") {
        this.error.family_number =
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.";
        Swal.fire(
          "",
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.",
          "error"
        );
      } else {
        this.error.family_number = "";
      }
    },
  },
  computed: {
    IdCardNumber: {
      get() {
        this.error.id_card_number = "";
        return this.form.id_card_number;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.form.id_card_number = val;
          if (this.form.id_card_number.length >= 15) {
            this.checkIdCardNumber();
          }
        }, 500);
      },
    },
    FamilyNumber: {
      get() {
        this.error.family_number = "";
        return this.form.family_number;
      },
      set(val) {
        if (this.timeout2) clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          this.form.family_number = val;
          if(this.form.family_number.length >= 15){
              this.checkFamilyNumber();
            }
        }, 500);
      },
    },
  },
  mounted() {
    this.getSetting();
    this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 172px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -4px;
  width: 100%;
}

.form-control-lg {
  height: 61px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}
</style>